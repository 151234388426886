import { render, staticRenderFns } from "./VirtualQues.vue?vue&type=template&id=0d314cc4&scoped=true"
import script from "./VirtualQues.vue?vue&type=script&lang=js"
export * from "./VirtualQues.vue?vue&type=script&lang=js"
import style0 from "./VirtualQues.vue?vue&type=style&index=0&id=0d314cc4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d314cc4",
  null
  
)

export default component.exports