    <template>
  <div>
    <h4 class="mb-5">{{project.name}}</h4>
    <v-card>
      <v-card-title>Add Virtual Questions</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field label="Question" v-model="virtualQuestion.text"  ></v-text-field>

          <v-select
            v-validate="'required'"
            data-vv-name="chart type"
            :error-messages="errors.collect('chart type')"
            label="Chart Type"
            :items="charts"
            v-model="virtualQuestion.chart"
          ></v-select>

          <v-text-field label="Count from Question" v-model="virtualQuestion.countFrom.question"></v-text-field>

          <v-text-field label="Tab Name" v-model="virtualQuestion.category"></v-text-field>

          <v-checkbox
            label="Show Chart"
            v-validate="'required'"
            data-vv-name="show chart"
            :error-messages="errors.collect('show chart')"
            v-model="virtualQuestion.shown"
            disabled
          ></v-checkbox>
        </v-form>
      </v-card-text>
    </v-card>

    <v-btn class="mt-5 ml-3" right color="primary" @click="addRefQuestion">Add Reference Question</v-btn>
    <!--<v-btn class="mt-5 ml-3" color="primary" @click="all">Expand all</v-btn>-->
    <v-btn class="mt-5 ml-3" color="primary" @click="none">Close all</v-btn>

    <div class="mt-5">
      <v-expansion-panels>
        <v-expansion-panel
          v-model="panel[rIdx]"
          v-for="(refQuestion, rIdx) in virtualQuestion.ref_questions"
          :key="rIdx"
        >
          <v-expansion-panel-header>
            <h3>Reference Question</h3>
            <template v-slot:actions>
              <v-icon
                color="primary"
                @click="removeRefQuestion(refQuestion, rIdx)"
              >mdi-alpha-x-circle-outline</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
             <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="virtualQuestion.ref_questions[rIdx].text"
                label="Reference Question"
                v-validate="'required'"
                data-vv-name="reference question"
                :error-messages="errors.collect('reference question')"
                solo
              ></v-text-field>

              <v-text-field
                v-model="virtualQuestion.ref_questions[rIdx].display_text"
                label="Display Text"
                solo
              ></v-text-field>

              <v-text-field v-model="virtualQuestion.ref_questions[rIdx].alias" label="Alias" solo></v-text-field>

              <tr v-for="(filter, idx) in refQuestion.filters" :key="idx">
                <td>
                  <v-text-field
                    label="Filter"
                    v-validate="'required'"
                    data-vv-name="filter"
                    :error-messages="errors.collect('filter')"
                    v-model="refQuestion.filters[idx]"
                    class="rowWidth"
                    solo
                  >
                    <template slot="append">
                      <v-icon @click="addFilter(rIdx)">mdi-shape-circle-plus</v-icon>
                      <v-icon @click="removeFilter(rIdx,idx)">mdi-alpha-x</v-icon>
                    </template>
                  </v-text-field>
                </td>
              </tr>

              <v-btn color="primary" @click="addFilter(rIdx)">Add Filter</v-btn>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

   
      <v-btn
        class="mt-5 ml-5"
        slot="activator"
        right
        ripple
        bottom
        fab
        fixed
        color="primary"
        @click="addQuestions"
      >Save</v-btn>


    <v-bottom-sheet v-model="redirect" persistent>
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          text
          color="primary"
          @click="addVirtual"
        >Add another virtual question</v-btn>
        <v-btn
          class="mt-6"
          text
          color="primary"
          @click="projectPage"
        >Go back to project page</v-btn>
        <div class="py-3">Please choose you next action..</div>
      </v-sheet>
    </v-bottom-sheet>



  </div>
</template>

<script>
import RestResource from "@/services/dataServiceBuyer.js";
const service = new RestResource();
export default {
  data() {
    return {
      charts: ["PIE", "BAR", "HALFDONUT", "WORDCLOUD"],
      virtual: [],
      filters: [],
      panel: [],
      dialog: false,
      valid: true,
      redirect: false,
      project: {},
      virtualQuestion: {
        type: "VIRTUAL",
        ref_questions: [],
        countFrom: {},
        shown: true
      }
    };
  },

  mounted() {
    this.loadProject();
  },
  methods: {
    addFilter(rowIdx) {
      // console.log(`Test.. ${rowIdx}`)
      this.virtualQuestion.ref_questions[rowIdx].filters.push("");
      // this.project.questions.push(this.virtualQuestion);
    },

    removeFilter(rowIdx, filterIndex) {
      this.virtualQuestion.ref_questions[rowIdx].filters.splice(filterIndex, 1);
    },

    addNewFilter() {
      // this.filters.push(this.filter);
      // this.ref_questions.filters = this.filters;
      // this.filter = {};
      // this.filter.push = ({});
    },

    // all() {
    // this.panel = [...Array(this.items).keys()].map(_ => true)
    // this.panel[0] = true
    // [...Array(this.virtualQuestion.ref_questions.keys())].map(a => {
    //   console.log(`TEST.. ${a}`)
    // })
    // },

    none() {
      this.panel = [];
    },

    addRefQuestion() {
      this.virtualQuestion.ref_questions.push({
        filters: [""]
      });
    },

    removeRefQuestion(rIdx, quesIdx) {
      this.virtualQuestion.ref_questions.splice(quesIdx, 1);
    },

    loadProject() {
      this.$setLoader();
      service.fetchProject({ projectId: this.$route.params.projectId }).then(r => {
        this.project = r.data;
        this.$disableLoader();
      });
    },

    async addQuestions() {
      // console.log(this.project.questions.length)
      this.project.questions.push(this.virtualQuestion);
      let v = await this.$validator.validateAll();
      if (v) {
        try {
          this.$setLoader();
          service.updateProject(this.project).then(r => {
            r;
            this.loadProject();
            this.$disableLoader();
            alert("Successfully Added Virtual Question")
            this.redirect = true
          });
        } catch (error) {
          alert("Error" + error)
        }
      }
    },

    projectPage() {
      window.location.href = `../configureProject/${this.project._id}`;
    },

    redirection() {
      this.success = false;
      this.redirect = true;
    },

    addVirtual() {
      this.redirect = false;
      window.location.reload();
    }
  }
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap.scss";
.displayText {
  font-weight: 700;
  padding-left: 20px !important;
  padding-top: 25px !important;
  font-size: 15px !important;
}
.txtField {
  width: 75%;
}

.rowWidth {
  width: 495px;
}
</style>


   